<template>
  <b-col md="12" lg="6" class="pl-0 p-0 pr-lg-2">
    <label>Huurperiode</label>
    <date-range-picker
      v-model="dateRange"
      :minDate="minDate"
      :locale-data="locale"
      :date-format="dateFormat"
      :maxDate="maxDate"
      :ranges="false"
    />
  </b-col>
</template>

<script>
import dayjs from 'dayjs'
import DateRangePicker from 'vue2-daterange-picker'
import { getClosedDays } from '@/services/WarehouseService'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import nlLocale from '@/locales/nl-calendar.json'

export default {
  components: { DateRangePicker },
  props: {
    fullwidth: {
      type: Boolean,
      required: false,
      default: false
    },
    mobile: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      dateRange: {
        startDate: new Date(),
        endDate: new Date()
      },
      startDate: '',
      endDate: '',
      updatingItem: false,
      closedDays: []
    }
  },
  computed: {
    locale() {
      return nlLocale
    },
    formattedStartDate() {
      return dayjs(this.startDate).format('DD/MM/YYYY')
    },
    formattedEndDate() {
      return dayjs(this.endDate).format('DD/MM/YYYY')
    },
    DateTimeBusinessStart: function() {
      return this.$store.state.DateTimeBusinessStart
    },
    minDate() {
      return dayjs().format('YYYY-MM-DD')
    },
    maxDate() {
      return dayjs()
        .add(27, 'days')
        .format('YYYY-MM-DD')
    },
    DateTimeBusinessEnd: function() {
      return this.$store.state.DateTimeBusinessEnd
    },
    activeWarehouse: function() {
      return this.$store.state.activeWarehouse
    }
  },
  watch: {
    dateRange: {
      handler(val) {
        this.startDate = dayjs(val.startDate).format('YYYY-MM-DD')
        this.endDate = dayjs(val.endDate).format('YYYY-MM-DD')
      },
      deep: true
    },
    formattedEndDate() {
      this.updatingItem = true
      this.$store.commit('setDateTimeBusinessStart', {
        date: this.formattedStartDate
      })

      this.$store.commit('setDateTimeBusinessEnd', {
        date: this.formattedEndDate
      })

      this.updatingItem = false
    }
  },
  async created() {
    this.closedDays = await this.getFormattedClosedDays()
    if (this.activeWarehouse === '') return

    this.dateRange.startDate = dayjs(
      this.DateTimeBusinessStart,
      'DD-MM-YYYY'
    ).toDate()
    this.dateRange.endDate = dayjs(
      this.DateTimeBusinessEnd,
      'DD-MM-YYYY'
    ).toDate()
  },
  methods: {
    dateFormat(classes, date) {
      if (!classes.disabled) {
        classes.disabled = this.closedDays.includes(
          dayjs(date).format('YYYY-MM-DD')
        )
      }
      return classes
    },
    async getFormattedClosedDays() {
      const closedDays = await getClosedDays()

      return closedDays
    },
    preventInput($event) {
      $event.preventDefault()
    }
  }
}
</script>
<style lang="scss">
// media query for mobile devices
@media (max-width: 767px) {
  .date-range-picker,
  .drp-calendar {
    width: 100%;
    max-width: 100% !important;
  }

  .daterangepicker.ltr.show-calendar.openscenter.linked {
    width: 100%;
    left: 0;
    transform: none;
  }

  .daterangepicker .calendar-table td,
  .daterangepicker .calendar-table th {
    height: 32px;
  }
}
.daterangepicker .calendar-table td,
.daterangepicker .calendar-table th {
  border-radius: 0px !important;
}

.daterangepicker {
  border-radius: 0px;
}

.daterangepicker option.disabled,
.daterangepicker td.disabled {
  color: #2c2c2c;
  cursor: not-allowed;
  text-decoration: line-through;
  text-decoration-color: #ff0000aa;
  text-decoration-thickness: 2px;
  background-color: #d9d9d9;
}
.daterangepicker {
  font-family: 'Open Sans', 'Helvetica Neue', Arial, 'Noto Sans', sans-serif,
    'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji' !important;
}
.vue-daterange-picker {
  width: 100%;
  text-align: center;

  .reportrange-text {
    line-height: 1.5;
    color: #495057;
    padding: 0.55rem 2rem;
    font-size: 1rem;
    border: 1px solid #f0f0f0 !important;
  }
}
.daterangepicker td.active,
.daterangepicker td.active:hover {
  background-color: #f3c500 !important;
}
.vue-daterange-picker {
  .btn-success {
    background-color: #f3c500 !important;
  }
  .btn-secondary {
    background-color: #d9d9d9 !important;
  }
}
.vc-container {
  border-radius: 0px;
}

.vc-highlight {
  background-color: #f3c500 !important;
}
@media only screen and (max-width: 1450px) {
  .datepicker-wrapper {
    position: fixed;
    z-index: 1000;
    left: 0;
    height: 100vh;
    width: 100% !important;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    zoom: 1;
    background-color: #80808042;
  }

  .datepicker-popup {
    width: 438px;
    height: 239px;
    zoom: 1.5;
  }
}
.vfc-multiple-input {
  display: flex;
  justify-content: space-between;

  input {
    border-radius: 0px !important;
    width: calc(50% - 10px);
    margin-bottom: 10px;
    padding-left: 16px;
    height: 43px;
  }
  input::placeholder {
    color: #495057;
    text-align: left;
  }
}

.vfc-calendars-container {
  color: #495057;
  font-family: 'Open Sans', 'Helvetica Neue', Helvetica, sans-serif;

  .vfc-content {
    .vfc-week > .vfc-day > {
      .vfc-today {
        background-color: #f3c500;
        border-radius: 0px;
      }
    }
  }
}

.vfc-main-container {
  border-radius: 0px;
}

.vfc-navigation-buttons div,
.vfc-separately-navigation-buttons div {
  margin: 18px 10px;
}

.vfc-week .vfc-day span.vfc-span-day.vfc-marked {
  background-color: #3d464d;
  border-radius: 0;
}

.vfc-week .vfc-day span.vfc-span-day.vfc-marked:before {
  top: 0;
  left: 0;
  content: '';
  position: absolute;
  background-color: #e5eaeb;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.vfc-week .vfc-day span.vfc-span-day.vfc-hover:hover,
.vfc-week .vfc-day span.vfc-span-day.vfc-hovered {
  background-color: #3d464d;
  color: white;
  border-radius: 0px;
}

.vfc-week .vfc-day .vfc-base-start,
.vfc-week .vfc-day .vfc-base-end {
  position: absolute;
  background: #3d464d;
  width: 50% !important;
  border-radius: 0 !important;
  border-right-width: 0 !important;
  height: 100%;
}

.vfc-top-date span.vfc-underline {
  cursor: pointer;
  -webkit-text-decoration: underline rgb(242, 101, 34);
  text-decoration: underline rgb(242, 101, 34);
  text-decoration-line: underline;
  text-decoration-thickness: initial;
  text-decoration-style: initial;
  text-decoration-color: rgb(242, 101, 34);
}

h2.vfc-top-date {
  color: white;
}

.vfc-content.vfc-content-MY-picker {
  background-color: #2c2c2c;
}
.vfc-item.vfc-selected {
  background-color: #f3c500 !important;
}
</style>
